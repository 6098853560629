.upload-button {
    position: relative;
    margin: 0px;
    margin-left: 20px;
    width: 100px;
    height: 100px;
    color: white;
    border-radius: 5px;
    padding: 10px;
    background-color: var(--primary-shade);
    transition: background-color 0.3s;
}

.upload-button:hover {
    background-color: var(--primary);
    cursor: pointer;
}

.error {
    color: var(--danger);
}

.file-progress-bar {
    width: 200px;
    height: 30px;
    text-align: center;
    overflow: hidden;
    border-radius: 15px;
    border: 2px solid green;
    display: inline-block;
    margin-top: 5px;
}

.cancel-upload {
    transform: translateY(-9px);
    margin-left: 20px;
    border-radius: 15px;
    padding: 0px 6px;
    color: white;
    background-color: red;
    border: 2px solid red;
    cursor: pointer;
}

.file-progress {
    height: 100%;
    width: 0px;
    overflow: hidden;
    background-color: green;
    color: white;
    transition: 0.2s;
}
