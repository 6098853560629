body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --datepicker-size: 40px;
}

.datepicker-label {
    display: inline-block;
    position: relative;
    margin-bottom: 0px;
    padding-left: var(--datepicker-size);
    height: var(--datepicker-size);
    width: 175px;
}

.datepicker-input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
}

.datepicker-input::-webkit-calendar-picker-indicator {
    opacity: 0;
    transform: translateX(-100%);
    position: absolute;
    top: 0px;
    left: 0px;
    width: var(--datepicker-size);
    height: 100%;
    cursor: pointer;
  }

.datepicker-button {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: var(--datepicker-size);
    z-index: 1;
}